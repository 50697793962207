import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  @ViewChild('Cf',{static: false}) cf: ElementRef;
  @ViewChild('Unsubscribing',{static: false}) unsubscribing: ElementRef;
  @ViewChild('FS',{static: false}) fs: ElementRef;
  @ViewChild('IA',{static: false})ia: ElementRef;
  @ViewChild('Cookies',{static: false})cookies: ElementRef;
  @ViewChild('WYS',{static: false})wys: ElementRef;
  constructor() { }
  scroll(elem:ElementRef) {
    elem.nativeElement.scrollIntoView();
  }
  ngOnInit() {
  }

}
