
import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { State} from './store/selectors'
import { loadUtilitysCountries, loadUtilitysPOS } from './store/app-utilities/app-utilities.actions';
import { updateAppLang } from './store/app-setting/app-setting.actions';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(private translate: TranslateService,readonly store:Store<State>) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
  
     // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');
    sessionStorage.setItem('loaded','true'); 

    if(localStorage.getItem('lang') && ((localStorage.getItem('lang') != null || localStorage.getItem('lang') != 'null'))){
      translate.use(localStorage.getItem('lang'));
      this.store.dispatch(updateAppLang({ Lang: localStorage.getItem('lang') }));
    }
    else{
      // the lang to use, if the lang isn't available, it will use the current loader to get them
      localStorage.setItem('lang','ar');
      translate.use('ar');
      this.store.dispatch(updateAppLang({ Lang:'ar' }));
    }
  
  }
  ngOnInit(){
    this.store.dispatch(loadUtilitysPOS());

    this.store.dispatch(loadUtilitysCountries({lang:this.translate.currentLang}));
  }
}
