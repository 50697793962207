<div class="terms-page">
    <app-header class="header"></app-header>
</div>

<div class="wraber terms-content">
    <div class="container">
      <div class="row title">
        <h1>{{"TermsOfUse.TermsOfUse" | translate}}</h1>
      </div>
      <div class="row align-self-center  justify-content-center">
     
        <div class="col-xs-12 col-sm-8 col-md-10 align-self-center justify-content-center  ">
          <div class="boxNewGrid privcy-terms-content hotelTerms">
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title1" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc1" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title2" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc2" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title3" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc3" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title4" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc4" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title5" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc5" | translate}}</p>
              </div>
            </div>
  
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title6" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc6" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title7" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc7" | translate}}</p>
              </div>
            </div>


            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title8" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc8" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title9" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc9" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title10" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc10" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title11" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc11" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title12" | translate}}</h3>
              <h3 id="a1" >{{"TermsOfUse.title13" | translate}}</h3>

              <div class="accord-dis">
                <p>{{"TermsOfUse.desc13" | translate}}</p>
              </div>
            </div>




            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title14" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc14" | translate}}</p>
                <ul>
                  <li>
                    {{"TermsOfUse.list14.1" | translate}}
                  </li>
                  <li>
                    {{"TermsOfUse.list14.2" | translate}}
                  </li>
                  <li>
                    {{"TermsOfUse.list14.3" | translate}}
                  </li>
                  <li>
                    {{"TermsOfUse.list14.4" | translate}}
                  </li>
                </ul>
              </div>
           
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc14.2" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title15" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc15" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title16" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc16" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title17" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc17" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title18" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc18" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title19" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc19" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title20" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc20" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title21" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc21" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title22" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc22" | translate}}</p>
              </div>
            </div>


            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title23" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc23" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title24" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc24" | translate}}</p>
              </div>
            </div>


            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title25" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc25" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title26" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc26" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title27" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc27" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title28" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc28" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title29" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc29" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title30" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc30" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title31" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc31" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title32" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc32" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title33" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc33" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title34" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc34" | translate}}</p>
              </div>
            </div>

            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title35" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc35" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title36" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc36" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title37" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc37" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title38" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc38" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title39" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc39" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title40" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc40" | translate}}</p>
              </div>
            </div>




            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title41" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc41" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title42" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc42" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title43" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc43" | translate}}</p>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 id="a1" >{{"TermsOfUse.title44" | translate}}</h3>
              <div class="accord-dis">
                <p>{{"TermsOfUse.desc44" | translate}}</p>
              </div>
            </div>




          </div>
        </div>
      </div>
  
    </div>
    </div>

<app-footer></app-footer>