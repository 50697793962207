<div class="about-page">
  <!-- #header -->
  <app-header id="header"></app-header>

  <!-- #header -->
</div>

<section id="intro" class="clearfix">
  <div class="container d-flex h-100">
    <div class="row justify-content-center align-self-center">
      <div class="col-md-6 intro-info order-md-first order-last">
        <h2>An Easy Book Easy<br /><span>Go!</span> Online Solution</h2>
        <div>
          <a href="" class="btn-get-started scrollto">Get Started</a>
        </div>
      </div>

      <div class="col-md-6 intro-img order-md-last order-first">
        <img src="../../../assets/img/intro-img.svg" alt="" class="img-fluid" />
      </div>
    </div>
  </div>
</section>
<!-- #intro -->
<main id="main">
  <!--==========================
      About Us Section
    ============================-->
  <section id="about">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6">
          <div class="about-img">
            <img src="../../../assets/img/about-us-plane.jpg" alt="plane" />
          </div>
        </div>

        <div class="col-lg-7 col-md-6">
          <div class="about-content">
            <h2>Company’s Profile</h2>
            <!-- <h3>It all started with a simple idea.</h3> -->
            <p>
              We are Kuwait’s leading Online Travel Company with global market
              reach. We are a professional Online Travel Company and deals in
              the entire travel related products worldwide. We are specialized
              in providing airline tickets, hotel reservations, transportation,
              sightseeing and tour packages in all parts of the world with the
              best price and excellent services. Bookingwep core value
              differentiator is delivery of fastest and trusted user experience.
              Be it in terms of quickest search and checkout flow, fast
              payments, settlement and refund processes. All this is backed by
              customer service excellence and innovations in our operations and
              back end.
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- #about -->

  <!--==========================
      Features Section
    ============================-->
  <section id="features">
    <div class="container">
      <div class="row feature-item">
        <div class="col-lg-6 wow fadeInUp">
          <img
            src="../../../assets/img/torest/beautiful-bright-hand-1252983-min.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
          <h3>Our Mission</h3>
          <p>
            Being widely recognized in its customer markets for providing
            best-in-class services and has continuously set the benchmark for
            the regional tourism industry. Our mission is to lead Kuwait's
            premier corporate, business and travel operator is matched by our
            commitment to total client satisfaction.
          </p>
        </div>
      </div>

      <div class="row feature-item mt-5 pt-5">
        <div class="col-lg-6 wow fadeInUp order-1 order-lg-2">
          <img
            src="../../../assets/img/torest/bump-collaboration-colleagues-1068523-min.jpg"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
          <h3>Our Vision</h3>
          <p>
            A clear vision reflects a clear direction of a business, apparently
            success! Our Vision is "To serve beyond expectations, mixing quality
            and price to come out with a unique client satisfaction".
          </p>
        </div>
      </div>
      <div class="row feature-item mt-5 pt-5">
        <div class="col-lg-6 wow fadeInUp">
          <img
            src="../../../assets/img/torest/cardboard-communication-connecting-1246743-min.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
          <h3>OUR OBJECTIVE</h3>
          <p>
            When the client is the priority, success comes by Default! We work
            on a clear professional policy and procedure to ensure quality. We
            always plan and target to increase our pool of satisfied clients, by
            spreading our branches to get closer to you Domestically, Regionally
            & Globally. We committed to continuously invest in advanced
            technologies to further enhance our services to our valued clients.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- #about -->

  <!--==========================
      Services Section
    ============================-->
  <section id="services" class="section-bg">
    <div class="container">
      <div class="section-header">
        <h3>Services</h3>
        <p>More than 100 employees at your service</p>
      </div>

      <div class="row align-items-center justify-content-center">
        <div class="col-md-2 wow bounceInUp" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon" style="background: #1e1c66">
              <fa-icon
                class="icon"
                [icon]="faPlane"
                aria-hidden="true"
                style="top: 0%; color: #fff"
              ></fa-icon>
            </div>
            <h4 class="title"><a href=""> Flight Reservation</a></h4>
          </div>
        </div>
        <div class="col-md-2 wow bounceInUp" data-wow-duration="1.4s">
          <div class="box">
            <div class="icon" style="background: #1e1c66">
              <fa-icon [icon]="faHotel" style="color: #fff"></fa-icon>
            </div>
            <h4 class="title"><a href="">Hotel Reservation</a></h4>
          </div>
        </div>

        <div
          class="col-md-2 wow bounceInUp"
          data-wow-delay="0.1s"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon" style="background: #1e1c66">
              <fa-icon [icon]="faCar" style="color: #fff"></fa-icon>
            </div>
            <h4 class="title"><a href="">Car Rental</a></h4>
          </div>
        </div>
        <div
          class="col-md-2 wow bounceInUp"
          data-wow-delay="0.1s"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon" style="background: #1e1c66">
              <fa-icon [icon]="faMapMarkedAlt" style="color: #fff"></fa-icon>
            </div>
            <h4 class="title"><a href="">Group Trips</a></h4>
          </div>
        </div>

        <div
          class="col-md-2 wow bounceInUp"
          data-wow-delay="0.1s"
          data-wow-duration="1.4s"
        >
          <div class="box">
            <div class="icon" style="background: #1e1c66">
              <fa-icon [icon]="faFutbol" style="color: #fff"></fa-icon>
            </div>
            <h4 class="title"><a href=""> Exhibitions and Conferences</a></h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- #services -->
</main>
<!-- <app-contact></app-contact> -->
<app-footer></app-footer>
