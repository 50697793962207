import { PointOfsaleModule } from '../../modules/point-ofsale/point-ofsale.module';
import { CurrencyModule } from '../../modules/currency/currency.module';
import { createAction, props } from '@ngrx/store';
import { AirPorts } from '../../interfaces/flight-search-result';
import { CountriescodeModule } from '../../modules/countriescode/countriescode.module';
import { HotelsCitiesModule } from '../../modules/hotels-cities/hotels-cities.module';

export const loadUtilitysAirports = createAction(
  '[UtilitysAirports] Load UtilitysAirports',
  props <{ lang: string }>()
);

export const loadUtilitysAirportsSuccess = createAction(
  '[UtilitysAirports] Load UtilitysAirports Success',
  props<{ airports: AirPorts[] }>()
);

export const loadUtilitysAirportsFailure = createAction(
  '[UtilitysAirports] Load UtilitysAirports Failure',
  props<{ error: any }>()
);

export const loadUtilitysCurrency = createAction(
  '[UtilitysCurrency] Load UtilitysCurrency'
);

export const loadUtilitysCurrencySuccess = createAction(
  '[UtilitysCurrency] Load UtilitysCurrency Success',
  props<{ Currency: CurrencyModule[] }>()
);

export const loadUtilitysCurrencyFailure = createAction(
  '[UtilitysCurrency] Load UtilitysCurrency Failure',
  props<{ error: any }>()
);

export const loadUtilitysPOS = createAction(
  '[UtilitysPOS] Load UtilitysPOS'
);

export const loadUtilitysPOSSuccess = createAction(
  '[UtilitysPOS] Load UtilitysPOS Success',
  props<{ POS: PointOfsaleModule }>()
);

export const loadUtilitysPOSFailure = createAction(
  '[UtilitysPOS] Load UtilitysPOS Failure',
  props<{ error: any }>()
);

export const loadUtilitysCountries= createAction(
  '[Countries] Load Countries',
  props <{ lang: string }>()
);

export const loadUtilitysCountriesSuccess = createAction(
  '[Countries] Load Countries Success',
  props<{ countries: CountriescodeModule[] }>()
);

export const loadUtilitysCountriesFailure = createAction(
  '[Countries] Load Countries Failure',
  props<{ error: any }>()
);

export const loadUtilitysCities= createAction(
  '[Cities] Load Cities'
);

export const loadUtilitysCitiesSuccess = createAction(
  '[Cities] Load Cities Success',
  props<{ cities: HotelsCitiesModule[] }>()
);

export const loadUtilitysCitiesFailure = createAction(
  '[Cities] Load Cities Failure',
  props<{ error: any }>()
);

