import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyapiService } from '../../../services/myapi.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
export interface userdata {
  AccessToken: string,
  Comment: number,
  Message: string,
  applicationUser: {
    AccessFailedCount: number,
    AccessToken: string,
    Email: string,
    EmailConfirmed: boolean,
    Id: string,
    LockoutEnabled: boolean,
    LockoutEndDateUtc: any,
    Logins: [],
    PhoneNumber: string,
    PhoneNumberConfirmed: false,
    UserName: string,
    FirstName :string,
    LastName :string
  }
}
@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {
  preloader:boolean = true;
  loginform: FormGroup;
  faEnvelope = faEnvelope;

  constructor(
    private myapi: MyapiService,
    private _snackBar: MatSnackBar,
    private Router: Router,
    public translate:TranslateService  ) { }

  ngOnInit() {
    this.loginform = new FormGroup({
      'Email': new FormControl('', [Validators.email, Validators.minLength(8), Validators.required]),
      'Password': new FormControl('', [Validators.required, Validators.minLength(6)]),
      'Isbase':new FormControl(0)
    })
    this.preloader =false;
  }
  onSubmit() {
    this.preloader =true;
    
    this.myapi.login(this.loginform.value).subscribe(
      (result:userdata) => {
        this.preloader=false;
        console.log(result);
        switch(result.Comment){
          case 0 :{
            console.log('somthing went wrong');
            this.openSnackBar("Sorry user does not exist","Login faield","snakFaild");
            break
          }
          case 1:{
            sessionStorage.setItem('cls',result.AccessToken);
            // sessionStorage.setItem('cls',result.applicationUser.AccessToken);
            this.openSnackBar("Welcome Back "+result.applicationUser.UserName,result.Message,"snakSuccess");
            this.toHomepage();
            break
          }
          case -2:{
      
            this.openSnackBar("Sorry user does not exist","Login faield","snakFaild");
            this.loginform.reset();
            this.loginform.updateValueAndValidity();
            break
          }
          case -1:{
            this.openSnackBar("User name or password is incorrect","Login faield",'snakFaild');
          }
        }
      }
    );

  }
  openSnackBar(message: string, action: string,Staus:string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass:[Staus]
    });
  }
  toHomepage() {
    this.Router.navigate(['/']);
  }
}