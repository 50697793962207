import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCoffee, faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin, faTwitter,faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Store } from "@ngrx/store";
import { selectSettingsFeature } from "../../store/selectors";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  faCoffee = faCoffee;
  faTwitter = faTwitter;
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faLinkedin = faLinkedin;
  faMapMarkerAlt = faMapMarkerAlt;
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faWhatsApp = faWhatsapp


  lan: string = localStorage.getItem("lang") ? localStorage.getItem("lang") as string : 'en';
  appSetting$ = this.store.select(selectSettingsFeature);
  constructor(
    private router: Router,
    private readonly store: Store
  ) { }

  ngOnInit(): void {
    this.appSetting$.subscribe((v) => {
      this.lan = v.currentLang;
    });
  }

  goToHome() {
    this.router.navigate(['/'])
  }

}
