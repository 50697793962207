<div class="web-header header">
  <div class="container" >
    <div class="row">

      <div class="col-2 p-0 d-flex align-items-center" style="cursor: pointerz;">
        <img (click)="goToHome()" width="100%" class="logo-head" src="assets/img/bookingwep/bookingwep.png">
      </div>

      <div class="col-5 align-items-center d-flex" style="text-align: start;" [ngClass]="{ ar: lan === 'ar' , en:lan==='en' }">
        <div class="app-info" >

          <div class="d-inline mx-2 apps">
            <!-- href="https://itunes.apple.com/in/app/bookingwep/id1466205895?mt=8" -->
            <a ><img class="app-icon"
                src="../../../assets/img/bookingwep/apple.svg" alt=""></a>
          </div>

          <div class="d-inline mx-2 apps">
            <!-- href="https://play.google.com/store/apps/details?id=com.bookingwep" -->
            <a><img class="app-icon"
                src="../../../assets/img/bookingwep/googleplay.svg" alt=""></a>
                
          </div>
          

          <!-- <div class="phone  d-inline mx-2">
            <a href="">+96555503393</a>
          </div> -->

          <div class="phone d-inline">
            <fa-icon class="icon phone-icon  mx-2" [icon]="faPhone"></fa-icon>
            <a href="tel:+96555503393">{{"footer.contact.phone" | translate}}</a>
          </div>

          <div class="phone d-inline" >
            <fa-icon class="whatsicon phone-icon  mx-2" [icon]="faWhatsApp"></fa-icon>
            <a target="_blank" href="https://wa.me/+96555503393">{{"footer.contact.phone" | translate}}</a>
          </div>
          <div class="hide">Coming soon</div>

        </div>


      </div>

      <div class="col-5" style="text-align: end;">
        <div class="actions ">

          <!-- <button class="actionBtn" mat-button [matMenuTriggerFor]="pos">
            {{translate.currentLang == 'en'? selectedPOSEn : selectedPOSAr}} <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
          <mat-menu #pos="matMenu">
            <button (click)="changePOS(pos)" *ngFor="let pos of allPOS" mat-menu-item>
              {{translate.currentLang == 'en'? pos.country_name : pos.country_nameAr}}
            </button>
          </mat-menu> -->

          <!-- <button *ngIf="!logedin" class="actionBtn" mat-button 
          (click)="goToSignUp()">
            {{ "user.signUp" | translate }}
          </button> -->

          <ng-container *ngIf="selectedCurrency$ |async as curruncey">
            <button class="actionBtn b-0 ms-0 ps-0 p-0" mat-button [matMenuTriggerFor]="currSwitcher"
              *ngIf="!isRestricted">
              <img width="20" height="15" src="{{curruncey['Image_Url']}}"> {{ curruncey["Currency_Code"] }} <mat-icon>
                arrow_drop_down</mat-icon>
            </button>
          </ng-container>
          <mat-menu #currSwitcher="matMenu">
            <button mat-menu-item *ngFor="let curruncy of Currencies$ |async" (click)="changeCurrency(curruncy)">
              <img width="25" height="25" [src]="curruncy.Image_Url"> {{ curruncy["Currency_Code"] }}
            </button>
          </mat-menu>


          <button class="actionBtn b-0 pad-0" mat-button [matMenuTriggerFor]="LangSwitch">
            {{lang | uppercase}}
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #LangSwitch="matMenu">
            <button mat-menu-item (click)="langSwitch('en')">EN</button>
            <button mat-menu-item (click)="langSwitch('ar')">العربية</button>
          </mat-menu>

          <button *ngIf="!logedin " class="actionBtn2 action2 p-0" mat-button (click)="goToLogin()">
            <mat-icon>person</mat-icon>
            {{ "user.login" | translate }}
          </button>

          <button *ngIf="logedin" class="actionBtn2 p-0" mat-button (click)="goToProfile()">
            <mat-icon>person</mat-icon>
            {{ "user.profile" | translate }}
          </button>


        </div>
      </div>

    </div>


  </div>

</div>
<div class="mobile-header header">
  <div class="container-fluid ">
    <div class="row align-items-center" [ngClass]="{ ar: lan === 'ar' }">

      <div class="col-5">
        <img (click)="goToHome()" width="100%" class="logo-head2" src="assets/img/bookingwep/bookingwep.png">



      </div>
      <div class="col-6 phone phone-mobile">
        <fa-icon class="icon mx-2" [icon]="faPhone"></fa-icon>
        <a href="tel:+96555503393">{{"footer.contact.phone" | translate}}</a>
      </div>
      <div class="col-1 ">



        <fa-icon class="iconMenu" [icon]="faBars" aria-hidden="true" (click)="toggleDetails()"></fa-icon>


      </div>



    </div>
    <div class="row justify-content-end">
      <div class="col-7 justify-content-end d-flex">
        <ul class="item-list" *ngIf="showDetails">

          <!-- <button *ngIf="!logedin " class="actionBtn action2" mat-button
               (click)="goToLogin()">
                 {{ "user.login" | translate }} / {{ "user.signUp" | translate }}
               </button>
     
               <button *ngIf="logedin" class="actionBtn " mat-button 
               (click)="goToProfile()">
               {{ "user.profile" | translate }}
               </button>
               <button class="actionBtn b-0 pad-0" mat-button [matMenuTriggerFor]="LangSwitch"  *ngIf="!isRestricted">
                 {{lang | uppercase}} 
               </button> -->

          <li *ngIf="!logedin" class="mb-2">
            <button class="actionBtn2 action2" mat-button (click)="goToLogin()">
              <mat-icon>person</mat-icon>
              {{ "user.login" | translate }}
            </button>
          </li>
          <li *ngIf="logedin" class="mb-2">
            <button class="actionBtn2" mat-button (click)="goToProfile()">
              <mat-icon>person</mat-icon>
              {{ "user.profile" | translate }}
            </button>
          </li>
          <li class="mb-2">
            <button class="actionBtn b-0 m-0 pad-0" mat-button [matMenuTriggerFor]="LangSwitch">
              {{lang | uppercase}}
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #LangSwitch="matMenu">
              <button mat-menu-item (click)="langSwitch('en')">EN</button>
              <button mat-menu-item (click)="langSwitch('ar')">العربية</button>
            </mat-menu>
          </li>
          <li class="mb-2">
            <ng-container *ngIf="selectedCurrency$ |async as curruncey">
              <button class="actionBtn action3 b-0 ms-0 ps-0 m-0 p-0" mat-button [matMenuTriggerFor]="currSwitcher"
                *ngIf="!isRestricted">
                <img width="20" height="15" src="{{curruncey['Image_Url']}}"> {{ curruncey["Currency_Code"] }}
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
            </ng-container>
            <mat-menu #currSwitcher="matMenu">
              <button mat-menu-item *ngFor="let curruncy of Currencies$ |async" (click)="changeCurrency(curruncy)">
                <img width="25" height="25" [src]="curruncy.Image_Url"> {{ curruncy["Currency_Code"] }}
              </button>
            </mat-menu>
          </li>

          <li class="mb-2 d-flex justify-content-between">
            <div class="d-inline ">
              <a href=""><img class="app-icon" src="../../../assets/img/bookingwep/apple.svg" alt=""></a>
            </div>

            <div class=" d-inline">
              <a href=""><img class="app-icon" src="../../../assets/img/bookingwep/googleplay.svg" alt=""></a>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>

</div>