import { Action, createReducer, on } from '@ngrx/store';
import { CurrencyModule } from '../../modules/currency/currency.module';
import { PointOfsaleModule } from '../../modules/point-ofsale/point-ofsale.module';
import * as settings from '../app-setting/app-setting.actions';


export const appSettingFeatureKey = 'appSetting';

export interface State {
  currency     : CurrencyModule,
  pos          : PointOfsaleModule,
  currentLang  : string 

}

export const initialState: State = {
  currency     : {
    "ID": 2027,
    "Currency_Code": "KWD",
    "Currency_Name": "Kuwait Dinar",
    "Is_Base_Currency": true,
    "Image_Url": "https://images.triphands.com/Content/Currencies/KWD.JPG",
    "rate": 1
},
  pos          : undefined,
  currentLang  : 'en'
};


export const reducer = createReducer(
  initialState,
  on(settings.updateAppCurrency , (state , param) => ({...state , currency:param.Currency})),
  on(settings.updateAppPOS , (state , param) => ({...state , pos:param.POS})),
  on(settings.updateAppLang , (state , param) => ({...state , currentLang:param.Lang})),
);

export function AppSettingReducer(
  state: State | undefined,
  action: Action) {
  return reducer(state, action);
}

