import { NgModule} from '@angular/core';
import { MatSnackBarModule ,MatSnackBar} from '@angular/material/snack-bar';
import { CommonModule, } from '@angular/common';
import { FlightSearchComponent } from './components/flight-search/flight-search.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateModule } from '@ngx-translate/core';
import { FilterCityPipe } from './pipes/filter-city.pipe';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { HighlighterPipe } from './pipes/highlighter.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { ExchangePipe } from './pipes/exchange.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { HourMinutePipe } from './pipes/hour-minute.pipe';
import { CouncodePipe } from './pipes/councode.pipe';
// import { LazyLoadImageModule } from 'ng-lazyload-image';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

export function rootloaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}
@NgModule({
    imports: [
        FontAwesomeModule, 
        HttpClientModule, 
        CommonModule,
        MatSnackBarModule,
        MatMenuModule,
        MatInputModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatButtonModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatCardModule,
        MatRadioModule,
        NgbModule,
        RouterModule,
        MatIconModule,
        TranslateModule,
        MatSelectModule,
        MatTabsModule,
        MatExpansionModule,
        MatButtonToggleModule
        
    ],
    declarations: [
        HeaderComponent,
        FlightSearchComponent,
        FooterComponent,
        FilterCityPipe,
        LimitToPipe,
        HighlighterPipe,
        ExchangePipe,
        HourMinutePipe,
        CouncodePipe
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        FlightSearchComponent,
        CommonModule,
        TranslateModule,
        FilterCityPipe,
        ExchangePipe,
        MatMenuModule,
        LimitToPipe,
        MatSnackBarModule,
        HighlighterPipe,
        FontAwesomeModule,
        HttpClientModule,
        MatInputModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatButtonModule,
        MatCardModule,
        RouterModule,
        MatNativeDateModule,
        MatSelectModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatIconModule,
        MatRadioModule,
        MatTabsModule,
        ExchangePipe,
        MatExpansionModule,
        HourMinutePipe,
        CouncodePipe,
        MatButtonToggleModule
    ],
    providers: [ExchangePipe,HourMinutePipe]
})

export class SharedModule { }