export const environment = {
    production: true,
    baseCurrency: 'KWD',
    endPoints: {
      offlineSeats:"http://154.41.209.73:7025",
      searchflow:  'https://wegosearch.bookingwep.com',
      BookingFlow:  'https://wegobook.bookingwep.com',
      FareRules:  'https://wegoprovider.bookingwep.com',
      asm:  'https://backofficeapi.bookingwep.com',
      Apihotels:  "https://hotels.bookingwepcom",
      hotelprepay: 'https://prepayapi.bookingwep.com',
      users:  'https://usersapi.bookingwep.com',
      admin:  'https://adminapi.bookingwep.com/',
      getDPayment:  'https://adminapi.bookingwep.com/',
      bookHotels: "https://hotels.bookingwep.com",
      hotelPrepay: 'https://prepayapi.bookingwep.com',
      backOffice: 'https://backofficeapi.bookingwep.com',
      FlightTop:'https://flightsearch.bookingwep.com',
      offers: {
        getAll: 'http://154.41.209.71:7893/api/GetAllOffersAPI?POS=',
        getByID: 'http://154.41.209.71:7893/api/GetOfferByIdAPI?OfferId=',
        BookOffer: "http://154.41.209.71:7895/api/BookOffer",
        RetriveItineraryDetails:'/api/Admin/RetriveItineraryDetails'
      }
    }
  };
  