import { environment } from 'src/environments/environment';
import { MyapiService } from 'src/app/services/myapi.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as appUtilities from '../app-utilities/app-utilities.actions';
import * as appSetting from '../app-setting/app-setting.actions';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';


@Injectable()
export class AppUtilitiesEffects {
  constructor(
    private actions$   : Actions,
    private apiService : MyapiService) {}

  loadAirports$ = createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysAirports),mergeMap(
    (lang) =>  this.apiService.UtilityAirports(lang.lang).pipe(
      map(v=>{
        return appUtilities.loadUtilitysAirportsSuccess({airports:v})
      }),
      catchError(err => {
        return of(appUtilities.loadUtilitysAirportsFailure({error:err}))
      })
    )
  ))
  )

  loadCurrency$ = createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysCurrency),mergeMap(
    () =>  this.apiService.currencyApi(environment.baseCurrency).pipe(
      map(v=>{
         
        return appUtilities.loadUtilitysCurrencySuccess({Currency:v})
      }),
      catchError(err => {
        return of(appUtilities.loadUtilitysCurrencyFailure({error:err}))
      })
    )
  ))
  )

  loadPOS$ = createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysPOS),mergeMap(
    () =>  this.apiService.pointOfSale().pipe(
     
      switchMap(v=>[
        appSetting.updateAppPOS({POS:v}),
        appUtilities.loadUtilitysPOSSuccess({POS:v})
      ]),
      catchError(err => {
        return of(appUtilities.loadUtilitysPOSFailure({error:err}))
      })
    )
  ))
  )

  loadCountries$ =createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysCountries),mergeMap(
    (lang) =>  this.apiService.countrycode(lang.lang).pipe(
      map(v=>{
        return appUtilities.loadUtilitysCountriesSuccess({countries:v})
      }),
      catchError(err => {
        return of(appUtilities.loadUtilitysCountriesFailure({error:err}))
      })
    )
  ))
  )
 
  loadCites$ =createEffect(()=>this.actions$.pipe(ofType(appUtilities.loadUtilitysCities),mergeMap(
    () =>  this.apiService.getHotelsCities().pipe(
      map(v=>{
        return appUtilities.loadUtilitysCitiesSuccess({cities:v.ci})
      }),
      catchError(err => {
        return of(appUtilities.loadUtilitysCitiesFailure({error:err}))
      })
    )
  ))
  )
}
