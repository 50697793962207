<div class="footer">
    <div class="container" [ngClass]="{ ar: lan === 'ar' , en:lan==='en' }">

        <div class="desktop-footer">
            <div class="row ">
                <div class="logo pt-5 pb-3 text-center text-sm-start col-6 col-md-4 col-lg-3" style="cursor: pointer;"
                    (click)="goToHome()">
                    <img class="w-100" src="../../../assets/img/bookingwep/bookingwep.png">
                </div>
            </div>

            <div class="row ">
                <div class="info col-12 col-lg-6">
                    <div class="address d-flex mb-2 sm-12">
                        <fa-icon class="icon mx-2" [icon]="faMapMarkerAlt"></fa-icon>
                        <p>{{"footer.contact.address" | translate}}</p>
                    </div>
                    <div class="contact  col-sm-12 text-center d-flex justify-content-lg-start justify-content-between">
                        <div class="d-flex">
                            <fa-icon class="icon mx-2" [icon]="faEnvelope"></fa-icon>
                            <a href="mailto: info@bookingwep.com">{{"footer.contact.email" | translate}}</a>
                        </div>

                        <div class="d-flex  mx-lg-4">
                            <fa-icon class="icon mx-2 phone-icon" [icon]="faPhone"></fa-icon>
                            <a href="tel:+96555503393" class="phone">{{"footer.contact.phone" | translate}}</a>
                        </div>

                        <div class="d-flex  mx-lg-4">
                            <fa-icon class="whatsicon phone-icon  mx-2" [icon]="faWhatsApp"></fa-icon>
                            <a target="_blank" href="https://wa.me/+96555503393">{{"footer.contact.phone" | translate}}</a>
                        </div>
                    </div>
                </div>
                <div class="links col-12 text-lg-end text-center col-lg-6 ">
                    <div class="mb-4 d-flex justify-content-lg-end justify-content-between">
                        <div class="m-end-lg">
                            <a class="mx-2" [routerLink]="['/Privacy']">{{"footer.PrivacyPolicy" | translate}} </a>
                            <a class="mx-2" [routerLink]="['/TermOfUse']">{{"footer.TermofUse" | translate}} </a>
                        </div>
                        <div>
                            <a class="mx-2" [routerLink]="['/aboutUs']">{{"footer.aboutUs" | translate}} </a>
                            <a class="mx-2" [routerLink]="['/Contact']">{{"footer.cotactUs" | translate}} </a>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end">
                        <a href="https://www.facebook.com/BookingWep" target="_blank"> 
                            <fa-icon class="icon m-end" [icon]="faFacebook"></fa-icon>
                        </a>

                        <!-- <a [routerLink]="['/']">
                            <fa-icon class="icon m-end" [icon]="faLinkedin"></fa-icon>
                        </a> -->
                        <a href="https://twitter.com/BookingWep" target="_blank">
                            <fa-icon class="icon m-end" [icon]="faTwitter"></fa-icon>
                        </a>
                        <a href="https://www.instagram.com/bookingwep" target="_blank">
                            <fa-icon class="icon m-end" [icon]="faInstagram"></fa-icon>
                        </a>
                    </div>
                </div>

                <div class="copy text-center mt-4">
                    <p>{{"footer.rights" | translate}}</p>
                </div>
            </div>
        </div>

        <div class="mobile-footer">
            <div class="row ">
                <div class="logo pt-5 pb-3 text-center text-sm-start col-6 col-md-4 col-lg-3" style="cursor: pointer;"
                    (click)="goToHome()">
                    <img class="w-100" src="../../../assets/img/bookingwep/bookingwep.png">
                </div>
            </div>


            <div class="info col-12 col-lg-6">
                <div class="address d-flex mb-3 sm-12">
                    <fa-icon class="icon m-end" [icon]="faMapMarkerAlt"></fa-icon>
                    <p>{{"footer.contact.address" | translate}}</p>
                </div>
                <div class="contact  col-sm-12 text-center d-flex justify-content-lg-start justify-content-between">
                    <div class="d-flex">
                        <fa-icon class="icon m-end" [icon]="faEnvelope"></fa-icon>
                        <a href="mailto: info@bookingwep.com">{{"footer.contact.email" | translate}}</a>
                    </div>

                    <div class="d-flex  mx-lg-4">
                        <fa-icon class="icon m-end phone-icon" [icon]="faPhone"></fa-icon>
                        <a href="tel:+96555503393" class="phone">{{"footer.contact.phone" | translate}}</a>
                    </div>
                </div>
            </div>


            <div class="d-flex justify-content-end my-3">
                <a href="https://www.facebook.com/BookingWep" target="_blank">
                    <fa-icon class="icon m-end" [icon]="faFacebook"></fa-icon>
                </a>

                <a [routerLink]="['/']">
                    <fa-icon class="icon m-end" [icon]="faLinkedin"></fa-icon>
                </a>

                <a href="https://twitter.com/BookingWep" target="_blank">
                    <fa-icon class="icon m-end" [icon]="faTwitter"></fa-icon>
                </a>

                <a href="https://www.instagram.com/bookingwep" target="_blank">
                    <fa-icon class="icon" [icon]="faInstagram"></fa-icon>
                </a>

            </div>

            <div class="mb-3 d-flex justify-content-center">
                <a class="mx-2" [routerLink]="['/Privacy']">{{"footer.PrivacyPolicy" | translate}}</a>
                <a class="mx-2" [routerLink]="['/TermOfUse']">{{"footer.TermofUse" | translate}}</a>
                <a class="mx-2" [routerLink]="['/aboutUs']">{{"footer.aboutUs" | translate}}</a>
                <a class="mx-2" [routerLink]="['/Contact']">{{"footer.cotactUs" | translate}}</a>
            </div>



            <div class="copy text-center ">
                <p>{{"footer.rights" | translate}}</p>
            </div>

        </div>
    </div>
</div>