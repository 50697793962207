import { createAction, props } from '@ngrx/store';
import { airItineraries, FareRules, FlightSearchResult, itinTotalFare, OfflineServiceModule, PassengersModule, Selectedflight } from '../../interfaces/flight-search-result';

import { metaFlightResponce, metaSearchResult, signatureMapedItinerary } from '../../interfaces/meta-flight';
import { FilterInputsModule } from '../../modules/filter-inputs/filter-inputs.module';
import { SearchFlightModule } from '../../modules/search-flight/search-flight.module';

export const loadFlightSearchs = createAction(
  '[FlightSearch] Load FlightSearchs',
  props<{search: SearchFlightModule}>()
);

export const loadFlightSearchsSuccess = createAction(
  '[FlightSearch] Load FlightSearchs Success',
  props<{ data: FlightSearchResult |metaFlightResponce |metaSearchResult }>()
);

export const loadFlightSearchsFailure = createAction(
  '[FlightSearch] Load FlightSearchs Failure',
  props<{err:any}>()
);


export const loadSelectedFlight = createAction(
  '[SelectedFlight] Load SelectedFlight',
  props<{searchid: string, sequenceNum: number, providerKey: string,pcc:string}>()
);

export const loadSelectedFlightSuccess = createAction(
  '[SelectedFlight] Load SelectedFlight Success',
  props<{ data: Selectedflight }>()
);

export const loadSelectedFlightFailure = createAction(
  '[SelectedFlight] Load SelectedFlight Failure',
  props<{err:any}>()
);

export const loadFares = createAction(
  '[Fares] Load Fares',
  props<{searchid: string, sequenceNum: number, providerKey: string}>()
);

export const loadFaresSuccess = createAction(
  '[Fares] Load Fares Success',
  props<{ data: FareRules[] }>()
);

export const loadFaresFailure = createAction(
  '[Fares] Load Fares Failure',
  props<{err:any}>()
);

export const loadOfflineSrvices = createAction(
  '[OfflineSrvices ] Load OfflineSrvices ',
  props<{searchid: string,POS:string}>()
);

export const loadOfflineSrvicesSuccess = createAction(
  '[OfflineSrvices ] Load OfflineSrvices  Success',
  props<{ data: OfflineServiceModule[] }>()
);

export const loadOfflineSrvicesFailure = createAction(
  '[OfflineSrvices ] Load OfflineSrvices  Failure',
  props<{err:any}>()
);

export const SaveBooking = createAction(
  '[SaveBooking ] SaveBooking ',
  props<{searchid: string, sequenceNum: number, body: PassengersModule, pkey: string,lang:string,ip:string,iplocation:string,selectedServices:any[],pcc:string}>()
);

export const SaveBookingSuccess = createAction(
  '[SaveBooking ] SaveBooking  Success',
  props<{ data: any }>()
);

export const SaveBookingFailure = createAction(
  '[SaveBooking ] SaveBooking Failure',
  props<{err:any}>()
);


export const updateSearchCretaria = createAction(
  '[FlightSearch] Update Search Cretaria',
  props<{searchData:SearchFlightModule}>()
);

export const filterFlight = createAction(
  '[FlightSearch] Filter Flight',
  props<{filter?:FilterInputsModule,airItineraries:signatureMapedItinerary[],roundTrip:boolean}>()
);

export const filterFlightSuccess = createAction(
  '[FlightSearch] Filter Flight Success',
  props<{airItineraries:signatureMapedItinerary[][]}>()
);

export const filterFlightFailure = createAction(
  '[SaveBooking ] Filter Flight Failure',
  props<{err:any}>()
);

export const sortFlight = createAction(
  '[FlightSearch] Sort Flight',
  props<{airItineraries:signatureMapedItinerary[] , sortType:number}>()
);

export const sortFlightSuccess = createAction(
  '[FlightSearch] Sort Flight Success',
  props<{airItineraries:signatureMapedItinerary[][]}>()
);

export const sortFlightFailure = createAction(
  '[FlightSearch] Sort Flight Failure',
  props<{err:any}>()
);
export const loadFlightSearchsLowestFare = createAction(
  '[FlightSearch] Load FlightSearchs Lowest Fare',
  props<{search: SearchFlightModule}>()
);

export const loadFlightSearchsLowestFareSuccess = createAction(
  '[FlightSearch] Load FlightSearchs Lowest Fare Success',
  props<{ data: itinTotalFare }>()
);

export const loadFlightSearchsLowestFareFailure = createAction(
  '[FlightSearch] Load FlightSearchs Lowest Fare Failure',
  props<{err:any}>()
);

export const resetFlightResults = createAction(
  '[FlightSearch] Reset Flight Search'
);


