<div class="privacy">
    <app-header class="header"></app-header>
</div>

<div class="wraber">
  <div class="container">
    <div class="row title">
      <h1>{{"PrivacyPolicy.PrivacyPolicy" | translate}}</h1>
    </div>
    <div class="row align-self-center  justify-content-center">
  
      <div class="col-xs-12 col-sm-8 col-md-10 align-self-center justify-content-center">
        <div class="boxNewGrid privcy-terms-content hotelTerms">
          <div class="accordianBlock">
            <h3 > {{"PrivacyPolicy.title1" | translate}}</h3>
          
            <div class="accord-dis">
              <p>
                {{"PrivacyPolicy.desc1" | translate}}
              </p>
            </div>
          </div>
          <div class="accordianBlock">
              <h3 > {{"PrivacyPolicy.title2" | translate}}
                </h3>
            
              <div class="accord-dis">
               <ul>
                <li>{{"PrivacyPolicy.desc2" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc3" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc4" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc5" | translate}}
                  
                </li>
               </ul>
              </div>
            </div>
            <div class="accordianBlock">
              <h3 > {{"PrivacyPolicy.title3" | translate}}
                </h3>
            
              <div class="accord-dis">
               <ul>
                <li>{{"PrivacyPolicy.desc6" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc7" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc8" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc9" | translate}}
                  
                </li>
               </ul>
              
               <p>{{"PrivacyPolicy.desc10" | translate}}</p>
             
              </div>
           
            </div>
            <div class="accordianBlock">
              <h3 > {{"PrivacyPolicy.title4" | translate}}
                </h3>
            
              <div class="accord-dis">
               <ul>
                <li>{{"PrivacyPolicy.desc11" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc12" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc13" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc14" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc15" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc16" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc17" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc18" | translate}}
                  
                </li>
               </ul>
               <p>{{"PrivacyPolicy.desc19" | translate}}</p>

              </div>
            </div>
            <div class="accordianBlock">
              <h3 > {{"PrivacyPolicy.title5" | translate}}
                </h3>
                <div class="accord-dis">
            
              <p>{{"PrivacyPolicy.desc20" | translate}}</p>
                </div>
            </div>

            <div class="accordianBlock">
              <h3 > {{"PrivacyPolicy.title6" | translate}}
                </h3>
            
              <div class="accord-dis">
               <ul>
                <li>{{"PrivacyPolicy.desc21" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc22" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc23" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc24" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc25" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc26" | translate}}
                  
                </li>
                <li>{{"PrivacyPolicy.desc27" | translate}}
                  
                </li>
             
               </ul>
               <p>{{"PrivacyPolicy.desc28" | translate}}</p>

              </div>
            </div>

            <div class="accordianBlock">
              <h3 > {{"PrivacyPolicy.title7" | translate}}
                </h3>
            
            
              <p>{{"PrivacyPolicy.desc29" | translate}}</p>
            </div>












            <div class="accordianBlock">
              <h3 > {{"PrivacyPolicy.title8" | translate}}
                </h3>
                <div class="accord-dis">
            
              <p>{{"PrivacyPolicy.desc30" | translate}}</p>
              <p>{{"PrivacyPolicy.desc31" | translate}}</p>


             
                <ul>
               
                 <li>{{"PrivacyPolicy.desc32" | translate}}
                   
                 </li>
                 <li>{{"PrivacyPolicy.desc33" | translate}}
                   
                 </li>
                 <li>{{"PrivacyPolicy.desc34" | translate}}
                   
                 </li>
                 <li>{{"PrivacyPolicy.desc35" | translate}}
                   
                 </li>
                 <li>{{"PrivacyPolicy.desc36" | translate}}
                   
                 </li>
                 <li>{{"PrivacyPolicy.desc37" | translate}}
                   
                 </li>
                 <li>{{"PrivacyPolicy.desc38" | translate}}
                   
                 </li>
                 <li>{{"PrivacyPolicy.desc39" | translate}}
                   
                 </li>
              
                </ul>
               </div>
            </div>
        </div>
      </div>
    </div>

  </div>



  <app-footer></app-footer>