import { Component, OnInit } from '@angular/core';
import { faPlane, faCar, faHotel, faMapMarkedAlt, faFutbol, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';

declare var $: any;
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
 scrolled:boolean = false;
 faHotel = faHotel;
 faCar=faCar;
 faMapMarkedAlt=faMapMarkedAlt;
 faFutbol=faFutbol;
 faPlane=faPlane;
 faPlaneDeparture=faPlaneDeparture;
  constructor() { }

  ngOnInit() {
  }

}
